import { CountryId } from '@/domain/models/country'
import { MyProfileMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { ProfileConfigs } from '@/domain/models/tenant/tenant-configs'
import { countryCompanyToTenantIdMapper } from '@/domain/models/tenant/tenant-id'
import { PersonDataResponse, Structures } from '@/domain/use-cases/person'
import { Card, LetterAvatar } from '@/main/components'
import { IdentityInfoContext } from '@/main/contexts'
import { genderCodes } from '@/main/factories/presenters/gender-codes'
import { getGenderNames } from '@/main/factories/presenters/gender-labels'
import { racialSelfDeclarationOptions } from '@/main/factories/presenters/racial-self-declaration-codes'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import RenderElseIf from '@/utils/components/RenderElseIf'
import {
  GridContainer, GridItem,
  Icon,
  Link,
  Typography,
} from '@naturacosmeticos/natds-web'
import { Divider } from '@naturacosmeticos/natds-web/dist/Components/Divider'
import { Spacing } from '@naturacosmeticos/natds-web/dist/Components/Spacing'
import React, { useContext } from 'react'

import { useConsultantDataStyles } from './consultant-data.styles'

export type ConsultantDataProps = {
  personData: PersonDataResponse
  groupSectorLeader: Structures[]
}

const getFirstTwoNames = (name: string) => name.split(' ').slice(0, 2).join(' ')

const SOURCESYSTEM_MINEGOCIO = '50'

export const ConsultantData: React.FC<ConsultantDataProps> = ({ personData: { person }, groupSectorLeader }) => {
  const {
    name, nickname, naturaCode, personId, additionalInformation, gender
  } = person
  const componentClasses = useConsultantDataStyles()
  const { consultantData, editButton: editButtonName } = usePageMessages(Page.MyProfile).messages as MyProfileMessages
  const {
    companyId,
    countryId,
    sourceSystem,
    isCNCeased
  } = useContext(IdentityInfoContext)

  const getRacialSelfDeclarationOptionById = (value: number) => {
    const option = racialSelfDeclarationOptions.find(option => option.value === value)
    return option?.label
  }

  const getGenderOptionById = (genderId) => getGenderOptions(countryId).find(({ value }) => value === genderId)

  const getGenderOptions = (countryId: CountryId) => (Object.keys(genderCodes)).map((key) => ({
    value: genderCodes[key],
    label: getGenderNames(countryId, key),
  }))

  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]

  const {
    consultantData: {
      editUrl,
      minegocioEditUrl
    },
    hasEditProfile,
    hasGender,
    hasSelfDeclaration,
    hasSocialName,
    showGroupSectorLeader
  } = useTenantConfigs(tenantId, Page.MyProfile) as ProfileConfigs
  const showDivider = hasGender || hasSelfDeclaration
  const editButtonLink = (sourceSystem && sourceSystem === SOURCESYSTEM_MINEGOCIO) ? minegocioEditUrl : `${editUrl}${personId}`
  const isCountryBrazil = countryId === CountryId.Brazil

  return (
    <Card data-testid="consultant-data" className={componentClasses.container}>
      <GridContainer spacing={2}>
        <GridItem className="natds2" lg={1} md={12} sm={12} xs={12}>
          <LetterAvatar size="huge" variant="h4" name={getFirstTwoNames(name)} />
        </GridItem>
        <GridItem lg={11} md={12} sm={12} xs={12}>
          {hasSocialName && (
            nickname ? <Typography className={componentClasses.typographyNickname} variant="h5">{nickname?.toLowerCase()}</Typography> :
            <Typography className={componentClasses.typographyMessageNickname} variant="h5">{consultantData.enterSocialName}</Typography>
          )}
          {isCountryBrazil && (
            <Typography className={componentClasses.typographyName} variant="subtitle1">{name.toLowerCase()}</Typography>
          )}
          {!isCountryBrazil && (
            <Typography className={componentClasses.typographyNickname} variant="h5">{name.toLowerCase()}</Typography>
          )}
          <Typography variant="body2" color="textSecondary">
            {consultantData.naturaCode}
            :&nbsp;
            {naturaCode}
          </Typography>
          {showGroupSectorLeader && groupSectorLeader && (
            <div className={componentClasses.groupSectorContainer}>
              <div>
                <Typography variant="body2" color="textSecondary" noWrap>
                  {groupSectorLeader[0].label}
                  :&nbsp;
                  {groupSectorLeader[0].value.toLowerCase()}
                </Typography>
              </div>
              <div>
                <Typography variant="body2" color="textSecondary" className={componentClasses.typographySector}>
                  {groupSectorLeader[1].label}
                  :&nbsp;
                  {groupSectorLeader[1].value.toLowerCase()}
                </Typography>
              </div>
            </div>
          )}
          {showDivider && (
            <Spacing paddingY="small" className={componentClasses.divider}>
              <Divider />
            </Spacing>
          )}
          <GridContainer justify="space-between">
            {hasGender && (
              <GridItem lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body2" color="textSecondary" className={componentClasses.typographyMessageRacial}>
                  {consultantData.gender}
                </Typography>
                <Typography variant="subtitle1">
                  {getGenderOptionById(gender).label}
                </Typography>
              </GridItem>
            )}
            {hasSelfDeclaration && (
              <GridItem lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body2" color="textSecondary" className={componentClasses.typographyMessageRacial}>
                  {consultantData.racialSelfDeclarationLabel}
                </Typography>
                <RenderElseIf
                  conditional={additionalInformation?.racialSelfDeclaration}
                  trueContent={<Typography className={componentClasses.typographyNickname} variant="subtitle1">{getRacialSelfDeclarationOptionById(additionalInformation?.racialSelfDeclaration)}</Typography>}
                  falseContent={<Typography variant="subtitle1">{consultantData.enterRacialSelfDeclaration}</Typography>}
                />
              </GridItem>
            )}
          </GridContainer>
        </GridItem>
      </GridContainer>
      <div className={componentClasses.editDiv}>
        {
          hasEditProfile && !isCNCeased && (
            <Link
              href={editButtonLink}
              underline="none"
              color="textPrimary"
              className={componentClasses.editButton}
            >
              <Typography variant="body1">
                {editButtonName}
              </Typography>
              <Icon
                name="outlined-action-edit"
                size="tiny"
                className={componentClasses.editIcon}
              />
            </Link>
          )
        }
      </div>

    </Card>
  )
}
